#title-dashboard {
  text-align: center;
  margin: 20vw 0;
}

.data-dashboard {
  display: flex;
  flex-direction: column;
}

.dashboard-section {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
}

.dashboard-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
}

.btn-dashboard-back {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 2px;
  border: none;
  border-radius: 20%;
}

.data-dashboard > h3 {
  height: 3rem;
  padding: 1rem;
}

.best-exp-item {
  font-size: 0.7rem;
  padding: 0 6px;
  margin-left: 1rem;
  cursor: pointer;
  line-height: 1.7;
}

.best-exp-item:hover {
  font-weight: bold;
  font-size: 0.75rem;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  align-items: center;

  color: rgb(255, 255, 255);
  height: 100vh;
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  color: rgb(255, 255, 255);
  margin-bottom: 100px;
}

.profile-container li {
  /* flex-wrap: wrap; */

  font-size: 1.2rem;
  font-weight: bold;
  list-style-type: none;
  color: rgb(255, 255, 255);
  padding: 2rem;
  opacity: 1;
  cursor: pointer;
}

.profile-container li:hover {
  border-bottom: 1px solid white;
}

.profile-container li,
a {
  text-decoration: none;
}

.list-container {
  display: flex;
  flex-direction: column;
}

.profile-list-container {
  display: flex;
  flex-direction: column;
}

.dashboard-container li {
  /* flex-wrap: wrap; */

  font-size: 1.2rem;
  font-weight: bold;
  list-style-type: none;
  color: rgb(255, 255, 255);
  padding: 2rem;
  opacity: 1;
  cursor: pointer;
}

.dashboard-container li:hover {
  border-bottom: 1px solid rgb(255, 255, 255);
}

.dashboard-container li,
a {
  text-decoration: none;
}

.general-data {
  padding: 2rem;
  background-color: rgb(var(--primary-color-soft));
}

.info-best-exp {
 cursor: pointer;
}

@media screen and (min-width: 820px) {
  .profile-list-container {
    display: none;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 3rem;
  color: black;
}

tr:nth-of-type(odd) {
  background: rgb(var(--primary-color-soft));
  width: 375px;
}

th {
  background: rgb(var(--main-color));
  color: white;
  font-weight: bold;
}

td:nth-child(even) {
  background-color: rgb(255, 255, 255);
}
td:nth-child(odd) {
  background-color: rgb(var(--primary-color-soft));
}
td,
th {
  padding: 6px;
  border: 1px solid #ccc;
  text-align: left;
}

@media only screen and (max-width: 768px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  .info-table td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  .info-table td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  .info-table td:nth-of-type(1):before {
    content: "Nº Cat";
  }
  .info-table td:nth-of-type(2):before {
    content: "Nº Exp";
  }
  .info-table td:nth-of-type(3):before {
    content: "Nº Users";
  }
  td:nth-of-type(4):before {
    content: "Mejor Exp";
  }

   .info-table td:nth-of-type(4):before {
    content: "Mes Actual"; 
  }
  /* td.best-exp:nth-of-type(1):before {
    content: "Top exp";
  } */
.info-best-exp {
  font-size: 0.7rem;
}

  } 

.accordion-wrapper .accordion-item h3.accordion-title {
	font-size: 1.75rem;
	margin: 0;
}

.accordion-wrapper .accordion-item h3.accordion-title button {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: rgb(var(--primary-color-soft));
	border-radius: 9px 9px 0 0;
	border: none;
	font-size: 1.2rem;
	width: 100%;
	text-align: left;
	color: rgb(var(--primary-color-hard));
	font-weight: bold;
	margin-top: 0.5rem;
	padding: 1rem;
	cursor: pointer;
}

.accordion-wrapper .accordion-item h3.accordion-title button.active {
	background-color: rgba(var(--second-color));
	color: #ffffff;
}

.accordion-wrapper
	.accordion-item
	h3.accordion-title
	button
	span.title-wrapper {
	display: block;
	position: relative;
	width: 100%;
	font-weight: 400;
}

.accordion-wrapper .accordion-item h3.accordion-title button span.icon-wrapper {
	width: 10%;
	display: flex;
	justify-content: center;
}

.accordion-wrapper
	.accordion-item
	h3.accordion-title
	button
	span.icon-wrapper
	span.minus {
	content: url("../../../public/imgs/accordion-icons/line.svg");
	width: 24px;
	height: 24px;
	display: none;
}

.accordion-wrapper
	.accordion-item
	h3.accordion-title
	button
	span.icon-wrapper
	span.plus {
	content: url("../../../public/imgs/accordion-icons/plus.svg");
	width: 24px;
	height: 24px;
	transform: rotate(90deg);
	transition-timing-function: ease-in;
	transition: all 1s;
	display: none;
}

.accordion-wrapper .accordion-item .accordion-panel .panel-close {
	background-color: white;
	box-sizing: border-box;
	padding: 0.5rem 1rem 0 1rem;
	opacity: 0;
	width: 100%;
	height: 0;
	overflow: hidden;
	transition-timing-function: ease-out;
	transition: all 0.2s;
	color: black;
}

.accordion-wrapper .accordion-item .accordion-panel .panel-open {
	overflow: hidden;
	background-color: white;
	/*background: #e0e0e0;*/
	padding: 1rem;
	box-sizing: border-box;
	color: black;
	opacity: 1;
	width: 100%;
	height: auto;
	transition-timing-function: ease-in;
	transition: all 0.2s;
	display: flex;
	flex-direction: Column;
	justify-content: space-evenly;
	align-items: center;
	text-align: center;
}

.accordion-img {
	width: 400px;
}

.accordion-wrapper .accordion-item .accordion-panel p {
	margin: 0;
}

.accordion-item {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: rgb(0, 0, 0);
	background-color: transparent;
	margin: auto;
}

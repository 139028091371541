.arrowdown {
	width: 1rem;
	margin-left: 1rem;
	padding-top: 0.4rem;
}

.dropdown-item {
	padding: 1rem;
	color: rgb(var(--primary-color-hard));
	font-size: 15px;
	font-weight: 400;
}
.dropdown-item:hover {
	opacity: 90%;
	transition: all 0.2;
	cursor: pointer;
	color: rgb(255, 255, 255);
}

.dropdown-btn:hover {
	opacity: 90%;
	transition: all;
	cursor: pointer;
	color: black;
}

.dropdown-container {
	position: relative;
	top: 20rem;
}

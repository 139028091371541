/* .navbar {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 4rem;
  z-index: 100;
  background-color: black;
  width: 100vmax;
  opacity: 95%;

 } */

.dropdown-btn {
	list-style: none;
	font-size: 17px;
	font-weight: bold;
	padding: 1rem;
	color: rgb(var(--primary-color-hard));
}

.dropdown-btn:hover {
	opacity: 90%;
	transition: all;
	cursor: pointer;
	color: rgb(var(--primary-color-hard));
}

.dropdown-content {
	list-style: none;
}

li.dropdown-btn > a {
	color: rgb(var(--primary-color-hard));
	text-decoration: none;
}

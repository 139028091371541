/* #formcategoryadmin{
  margin: 20px 10px;
} */

#title-admin-cat {
	margin: 20px 0;
	text-align: center;
	background-color: white;
	height: 10rem;
	padding: 4rem;
	margin-bottom: 0;
}

.barrabusquedacategory {
	display: flex;
	justify-content: center;
	height: 4rem;
	border: none;
	background-color: transparent;
	color: white;
	font-size: 1.5rem;
}
.barrabusquedacategory label {
	margin: 1rem;
	margin-left: 2rem;
}

.barrabusquedacategory input {
	height: 2rem;
	width: 30rem;
	margin: 1rem;
	font-size: 1.2rem;
	text-align: center;
	border-radius: 9px;
	background-color: transparent;
	border-color: white;
	color: white;
}
#link-create-cat {
	display: flex;
	justify-content: center;
}

.error-info {
	margin: 5rem 0;
	text-align: center;
	width: 100%;
	font-size: 2rem;
}

/* .form-wrap {
  margin: 20px 0;
} */
/* #input-search-field {
  width: 100%;
  font-size: 1.2rem;
}  */

.admin-category-wrapper {
	background-color: white;
}

.change::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: #fff;
	font-weight: 200;
}
.change:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #fff;
	opacity: 0.2;
	font-weight: 200;
}
.change::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #fff;
	opacity: 0.2;
	font-weight: 200;
}
.change:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #fff;
	font-weight: 200;
}

.exp-pic {
  object-fit: cover;
  width: 100%;
  box-shadow:
    -40px -40px 0 -35px rgb(var(--second-color)),
    40px 40px 0 -35px rgb(var(--main-color));
}

.exp-pic-pop {
  position: relative;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
}

.wrap-content {
  background-color: white;
}

.initial-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.accordion-section {
  border: none;
}

.precio-unidad {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: rgb(var(--primary-color-hard));
  margin-top: 1rem;
  font-weight: 600;
}

.normatives-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.normatives-list li {
  list-style: none;
  text-align: left;
  margin-bottom: 10px;
}

.normatives-list a {
  color: black;
}

.precio-total {
  padding: 1rem 0;
  display: flex;
  justify-content: end;
  font-size: 1.8rem;
  text-decoration: underline;
}

.btn-back {
  position: absolute;
  margin: 6px;
  right: 6%;
  text-transform: uppercase;
  font-size: 0.7rem;
  width: 4rem;
  height: 1.5rem;
  color: black;
  background-color: white;
  border: none;
  border-radius: 50px;
  display: none;
}

.experience-data {
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
}

.title-description {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 10px;

  text-align: center;
  align-items: center;
}

.booking-form {
  display: flex;
  flex-direction: column;
  justify-items: center;
  background-color: rgb(var(--primary-color-soft));
  justify-content: center;
}

#select-date {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid rgba(214, 210, 210, 0.594);
}

#select-quantity {
  display: flex;
  margin-top: 8px;
}

.tickets-booking {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
}

#select-quantity label {
  padding-right: 45px;
}
#select-quantity input {
  text-align: center;
  width: 50px;
  flex-grow: 0;
  border: none;
}

.description-text {
  margin: 12px 0;
  line-height: 1.5;
}

.photo-thumbnail {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
}

.img-top {
  justify-self: flex-start;
}

.button-quantity {
  width: 1rem;
  background-color: rgb(var(--primary-color-hard));
  color: white;
}

.tickets-booking {
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 2;
  border-bottom: 2px solid rgba(214, 210, 210, 0.594);
}

.pay-method {
  padding: 1rem 0;
  line-height: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 2px solid rgba(214, 210, 210, 0.594);
}

.pay-option {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.pay-option input {
  margin-right: 10px;
}

.right-align {
  display: flex;
  justify-content: flex-end;
}

.normatives {
  list-style: none;
  text-align: left;
  line-height: normal;
}

.check-out {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 2rem 0;
}

.booking-select {
  border-radius: 9px;
  max-width: 150px;
  border: none;
  box-shadow: 2px 2px 4px grey;
  background-color: white;
}

@media screen and (min-width: 1200px) {
  .photo-thumbnail {
    margin-bottom: 0;
  }

  .exp-pic {
    object-fit: cover;
    width: 400px;
    height: 320px;
    
  }

  .exp-pic-pop {
    width: 400px;
    height: 320px;
  }

  .normatives-price {
    align-items: flex-start;
  }

  .initial-wrap {
    flex-direction: row;
    height: 450px;
  }

  .title-description {
    align-items: flex-start;
    text-align: left;
    padding: 2rem;
  }

  #select-date,
  .tickets-booking,
  .pay-method,
  .check-out {
    border: none;
    align-items: center;
    flex-direction: column;
    padding: 0.5rem 0;
    margin-right: 4rem;
  }

  #booking-select {
    max-width: 30px;
  }

  .booking-form {
    flex-direction: row;
    height: 100%;
    width: 100%;
    align-items: center;
    background-color: rgb(var(--primary-color-soft));
    padding: 1rem;
  }

  .info-checkout-container {
    width: 100%;
  }
}

#popup-booking {
  display: flex;
  flex-direction: column;
  background-color: rgb(var(--primary-color-soft));
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  font-size: 10px;
}

.exp-pic-pop {
  padding: 2rem;
}

#popup-booking-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(var(--primary-color-hard), 0.9);
  align-items: center;
  justify-content: center;
  z-index: 5;
  padding: 3rem;
}

.pop-up-button-set {
  display: flex;
  width: 75%;
  justify-content: space-evenly;
  padding: 1rem;
}

.sold-out {
  z-index: 3;
  position:absolute; 
  top: 10%;
  height: 80%;
  transition: .5s ease;
  opacity:1;
}

.available-places {
  z-index: 3;
  position:absolute; 
  top: 10%;
  height: 80%;
  transition: .5s ease;
  opacity:0;
}
.general {
	margin: 2rem 0 3.5rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

.title-booking {
	font-size: 1.2rem;
	margin: 6px 0;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.wrap-booking {
	margin: 2rem 0;
	position: relative;
	border-radius: 0 0px 10px 10px;
	box-shadow: 3px 3px 20px rgb(0 0 0 / 25%);
	overflow: hidden;
	cursor: pointer;
	background-color: rgb(var(--primary-color-soft));
	width: 95%;
	max-width: 360px;
	display: flex;
	flex-direction: column;
	min-height: 260px;
}

.options-booking {
	display: flex;
}

.photo-booking {
	display: flex;
	justify-content: center;

	min-width: 100%;
}

.card-img-booking {
	width: 250px;
	border-radius: 20px;
	object-fit: cover;
	box-shadow: 2px 2px 4px grey;
}

.btns-bookings {
	position: relative;
	bottom: 0;
	display: flex;
	justify-content: space-evenly;
	width: 100%;
}

.padding-general {
	padding: 0 10px;
}

.img-card-otherbooking {
	object-fit: cover;
}

.margin-cards {
	margin: 1.5rem 1rem;
}

.btn-booking {
	width: 33%;
	color: white;
	background-color: black;
	height: 2.2rem;
	font-size: 1.2rem;
}

.header-booking {
	display: flex;
	justify-content: space-between;
	margin: 12px 0;
}

.contact-form {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  max-width: 580px;
}

.generalTítulo1 {
  margin-top: 20px;
  margin-bottom: 40px;
}

.checkForm {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.generalSelect {
  background-color: white;
}

.generalSelect option {
  font-weight: bold;
}

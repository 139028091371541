#rate-exp-header-voted {
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  margin: 20px 0;
  font-size: 1.4rem;
}

#rate-exp-header-unvoted {
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.4rem;
  padding: 12px 0;
  margin: 20px 0;
}

#rate-exp {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.title-rate-exp {
  font-size: 1.2rem;
  height: 4rem;
  display: flex;
  align-items: center;
}

.rateExperience-textarea {
  display: block;
  width: 100%;
  max-width: 600px;
  height: 4rem;
  border: none;
  margin: 16px 0;
  padding: 6px;
}

.stars-rate-experience {
  margin: 12px 0;
}

.btn-bottom {
  margin: 2rem 0;
}

.rate-experience {
  position: relative;
  padding: 2rem;
  height: 100%;
  min-height: calc(100vh - 312px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-warning {
  margin-top: 20%;
  font-size: 3rem;
  color: rgb(var(--primary-font-color));
  text-align: center;
  line-height: 2.5;
}

.card-deck {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	overflow: hidden;
	gap: 20px;
	padding: 1rem;
	background-color: white;
}

.info {
	height: 16rem;
	display: flex;
	text-align: center;
	line-height: 2.5;
	align-items: center;
}

.show-more {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 3rem;
	background-color: white;
	border: none;
	font-size: 1.2rem;
	cursor:pointer;
}

.spinner-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	height: 50vh;
	background-color: rgb(var(--primary-color-soft));
}

.result-title {
	text-align: center;
	font-size: 31px;
	padding: 1rem;
	background-color: white;
}

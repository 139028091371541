@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,400;0,500;1,400&family=Roboto:ital,wght@0,400;0,500;1,400&display=swap");
@font-face {
	font-family: "balloon";
	src: local("balloon"), url(./fonts/balloon.ttf) format("truetype");
}
@font-face {
	font-family: "babyballoon";
	src: local("babyballoon"), url(./fonts/babyballoon.ttf) format("truetype");
}
@font-face {
	font-family: "balloonparty";
	src: local("balloonparty"), url(./fonts/balloonparty.ttf) format("truetype");
}

@font-face {
	font-family: "buffalo";
	src: local("buffalo"), url(./fonts/buffalo.otf) format("opentype");
}
/* VARIABLES */
:root {
	--primary-color-soft: 246, 246, 248;
	--secondary-color-soft: 250, 229, 150;
	--primary-color-hard: 23, 62, 67;
	--secondary-color-hard: 20, 30, 39;
	--primary-font-color: 255, 255, 255;
	--secondary-font-color: 0, 0, 0;
	--error-font-color: 255, 217, 0;
	--primary-font: "babyballoon";
	--main-color: 63, 176, 172;
	--second-color: 71, 139, 214, 1;
	--hover-color: 23, 62, 67;
	--secondary-button-color: 250, 229, 150;
	--menu-font-color: 255, 255, 255;
	--button-font-color: 255, 255, 255;
}
/* FIN VARIABLES */

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html {
	background-image: url("../public/imgs/paper-texture.jpg");
	/* 	scroll-behavior: smooth;
 */
	scroll-padding-top: 100px;
}

body {
	width: 100%;
	font-family: "Roboto", sans-serif;
	letter-spacing: 0.03em;
	line-height: 1.6em;
	height: 100vh;
}

header {
	width: 100%;
	background-image: linear-gradient(
		132.6deg,
		rgba(71, 139, 214, 1) 23.3%,
		rgba(37, 216, 211, 1) 84.7%
	);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;

	height: 100px;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.custom-select {
	color: whitesmoke;
	background-color: rgb(var(--primary-color-hard), 0.1) !important;
	outline: none;
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
}

.custom-select option {
	font-size: 1rem;
	background-color: rgb(var(--primary-color-hard), 0.7) !important;
}

.opt-hover:hover {
	background: rgb(69, 63, 151) !important;
}

/*  */
.footer {
	font-size: 0.8rem;
	width: 100%;
	padding-top: 16px;
	color: white;
	background-color: rgb(var(--primary-color-soft));
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.navbar {
	position: absolute;
	display: flex;
	flex-direction: column;
	margin-top: 4.7rem;
	z-index: 3;
	background-color: rgb(var(--primary-color-hard));
}

.generalForm {
	border-radius: 9px;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 0.5rem 10px;
	height: auto;
	max-height: 100%;
	color: black;
	width: 400px;
	margin: 25px auto 5rem auto;
}

.generalForm-admin {
	border-radius: 9px;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 0.5rem 10px;
	height: auto;
	max-height: 100%;
	color: black;
}

.generalLabel {
	display: flex;
	justify-content: left;
	width: 75%;
	max-width: 400px;
	margin-top: 2rem;
	font-size: 1rem;
	font-weight: 300;
}

.generalInput {
	display: flex;
	width: 75%;
	max-width: 400px;
	justify-content: left;
	height: 2rem;
	border: none;
	margin: 0.5rem 0;
	padding-left: 6px;
	margin-bottom: 20px;
	border-bottom: 0.5px solid rgb(var(--second-color));
	background-color: transparent;
	color: black;
	font-weight: 300;
}

.generalInput-admin {
	display: flex;
	width: 100%;
	max-width: 400px;
	justify-content: left;
	height: 2rem;
	border: none;
	margin: 0.5rem 0;
	padding-left: 6px;
	border-bottom: 0.5px solid rgb(var(--second-color));
	color: rgb(0, 0, 0);
}

.generalTextarea-admin {
	padding: 1rem;
	border: none;
	border-bottom: 0.5px solid rgb(var(--second-color));
	width: 50%;
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
	height: auto;
	height: 100%;
	color: rgb(0, 0, 0);
	font-weight: 300;
}

.generalTextarea {
	padding-left: 6px;
	padding-top: 10px;
	border: none;
	border-bottom: 0.5px solid rgb(var(--second-color));
	width: 75%;
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
	/* height: auto;
	height: 100%; */
	background-color: transparent;
	color: black;
	font-weight: 300;
}

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: rgb(0, 0, 0);
	font-weight: 300;
	opacity: 1; /* Firefox */
}

.form-wrapper {
	position: relative;
	padding: 2rem;
	background: url("https://source.unsplash.com/random/?landscape");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
	z-index: 1;
	height: 100%;

	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.461);
}

.generalForm h2 {
	margin-top: 1rem;
	margin-bottom: 2rem;
}

.generalPhoto {
	border: none;
	border-radius: 9px;
	max-width: 250px;
	max-height: 100;
}

.errorValidation {
	/* color: rgb(var(--error-font-color)); */
	color: rgb(75, 75, 75);
	font-weight: lighter;
	font-size: 0.8em;
}

.generalButton {
	background-color: rgb(var(--second-color));
	color: white;
	padding: 5px 15px;
	letter-spacing: 1px;
	cursor: pointer;
	border-radius: 9px;
	border: none;
	margin: 5px;
	margin-top: 8px;
	transition: all 0.3s ease-in-out;
}

.generalButton-disabled {
	background-color: #a2a2a2;
	color: white;
	padding: 5px 15px;
	letter-spacing: 1px;
	cursor: pointer;
	border-radius: 9px;
	border: none;
	margin-top: 8px;
}

.generalButton:hover {
	transform: scale(1.1);
}

.generalTítulo1 {
	margin-top: 20px;
	margin-bottom: 40px;
	font-weight: 300;
}

.loading {
	font-size: 2rem;
	width: 100%;
	height: 60vh;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	padding: 12px 0 26px;
	margin: 15px 0 0;
	background-color: rgb(var(--primary-color-soft));
	background-color: white;
}

.fade_in {
	animation: fadeInAnimation ease 1s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

.show_no_results {
	animation: fadeInAnimation ease 6s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

.title-center {
	display: flex;
	justify-content: center;
	margin: 1.5rem 0;
	text-align: center;
}

.link-text {
	cursor: pointer;
}

.big-font {
	font-size: 1.2rem;
}

.terminosConditions {
	/* width: 90vw; */
	font-size: 0.7rem;
	display: flex;
	justify-content: center;
	gap: 12px;
	align-items: baseline;
	height: 50px;
}

.terminosConditions label{
	height: 100%;
	display: flex;
	align-items: center;
}

.terms-conditions {
	color: black;
	font-weight:600;
}
.terms-conditions a:visited,
.terms-conditions a:active {
	color: black;
	font-weight:600;
}

.checkboxBox {
	width: 0.7rem;
	height: 0.7rem;

}

.booking-select{
	padding: 6px;
	margin-top: 8px;
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.slideInDown {
	-webkit-animation-name: slideInDown;
	animation-name: slideInDown;
	-webkit-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
@-webkit-keyframes slideInDown {
	0% {
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
		visibility: visible;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}
@keyframes slideInDown {
	0% {
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		visibility: visible;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

#principal > h1 {
	font-family: "Montserrat";
	color: white;
	font-size: 39px;
	font-weight: 400;
	padding: 15px 25px;
	text-align: center;
	line-height: normal;
}

@media screen and (max-width: 768px) {
	#principal > h1 {
		font-size: 27px;
		text-align: center;
	}

	.navbar {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 500px;
		border: 1px solid black;
	}

	#slide {
		position: absolute;
		left: -100%;

		-webkit-animation: slide 0.01s;

		animation: slide 0.5s forwards;
		animation-delay: 0.1s;
	}

	@-webkit-keyframes slide {
		100% {
			left: 0;
		}
	}

	@keyframes slide {
		100% {
			left: 0;
		}
	}
}

@media screen and (max-width: 820px) {
	body {
		margin-left: auto;
		margin-right: auto;
	}

	/* 	.generalForm {
		margin-top: 0px;
		margin-bottom: 5rem;
		max-width: 500px;
		margin-right: auto;
		margin-left: auto;
	} */

	.navbar {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 370px;
		border: 1px solid black;
	}

	.generalForm {
		width: 300px;
		margin: 25px auto 5rem auto;
	}

	.generalAbout {
		width: 300px;
		margin: 25px auto 5rem auto;
	}

	#slide {
		position: absolute;
		left: -100%;
		height: auto;
		min-height: 100%;
		top: 1.4rem;
		background-color: rgb(var(--primary-color-soft));
		-webkit-animation: slide 0.01s;
		border: none;
		animation: slide 0.5s forwards;
		animation-delay: 0.1s;
	}

	@-webkit-keyframes slide {
		100% {
			left: 0;
		}
	}

	@keyframes slide {
		100% {
			left: 0;
		}
	}
}

@media screen and (min-width: 1400px) {
	body {
		margin-left: auto;
		margin-right: auto;
		max-width: 1400px;
	}

	.MuiSlider-thumb {
		color: rgb(var(--secondary-color-hard));
	}
	.MuiSlider-rail {
		color: rgb(var(--secondary-color-hard));
	}
	.MuiSlider-track {
		color: rgb(var(--secondary-color-hard));
	}
}

@media screen and (min-width: 768px) {
	/* .form-wrapper {
		padding: 4rem 25%;
	} */

	.generalForm {
		width: 500px;
		margin: 25px auto 5rem auto;
	}

	.generalInput,
	.generalLabel,
	.generalTextarea {
		width: 50%;
	}

	.generalAbout {
		width: 600px;
		margin: 25px auto 5rem auto;
	}
}

input[type=checkbox] {
	accent-color: rgb(var(--main-color));
	color:white;
  }


.editbooking button{

    border-radius: 30px;
    height: 3rem;
    width: 5rem;
    color: white;
    background-color: black;
    margin-left: 30px;
    margin-bottom: 75px;
   

}

.editbooking h2{

font-size: 1rem;
overflow: auto;
text-align: center;
margin-bottom: 20px;
}

.editbooking a{

   
}

.editbooking div{
    border: 1px solid black;
    background-color: rgba(235, 203, 62, 0.829);
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 75px;
    margin-bottom: 25px;
    width: 250px;
    height: 100px;
}
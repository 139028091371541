.others-opinions {
	display: flex;

	justify-content: space-between;
	padding: 2rem;
}
.stars-count-opinions {
	margin: 0;
	width: 80px;
}

.review-data {
	margin-left: 12px;
	width: 100%;
}

.detail-review {
	font-size: 10px;
}

#ratin-title {
	padding: 2rem 1rem;
	font-weight: 500;
	font-size: 25px;
	text-align: left;
}

.errMsn p {
  justify-content: center;
  padding: 1rem;
  display: none;
}

.errMsn {
  width: 50%;
  align-content: center;
  background-color: rgb(110, 8, 8);
  color: white;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

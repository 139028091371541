.card-category {
	position: relative;
	border-radius: 0 9px;
	box-shadow: 3px 3px 20px rgb(0 0 0 / 25%);
	overflow: hidden;
	cursor: pointer;
	background-color: white;
	width: 95%;
	max-width: 360px;
	display: flex;
	flex-direction: column;
	min-height: 260px;
}
.card-figure-category {
	overflow: hidden;
	width: 100%;
	height: 140px;
}
.card-thumbnail-category {
	overflow: hidden;
	width: 100%;
	height: 140px;
	object-fit: cover;
}
.title-card-category {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	margin: auto 6px;
}

.row-button-category {
	width: 100%;
	padding: 10px;
	display: flex;
	justify-content: space-around;
}

.btn-category-option {
	width: 30%;
	height: 2rem;
	color: white;
	background-color: black;
	border: none;
	border-radius: 12px;
}

.id-exp {
	min-width: 10%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.title-exp-edit {
	font-size: 14px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 75%;
}

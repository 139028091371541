.link-to {
  color: white;
  font-weight: bold;
  text-decoration: underline;
  margin: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: background-color .5s;
}

.link-to a, .link-to a:visited,
.link-to a:active {
  color: white;
}

.card {
	position: relative;
	overflow: hidden;
	cursor: pointer;
	background-color: white;
	min-height: 234px;
	width: 95%;
	max-width: 340px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	border: 0.5px solid rgb(129, 129, 129);
	border-radius: 0 9px;
    box-shadow: 3px 3px 20px rgb(0 0 0 / 25%);
}

.card-thumbnail {
	padding-bottom: 10px;
	overflow: hidden;
	height: 120px;
	object-fit: cover;
	align-self: center;
	width: 100%;
}

.card-thumbnail-map {
	padding-bottom: 10px;
	overflow: hidden;
	height: 100px;
	object-fit: contain;
	align-self: center;
	width: 100%;
}

.card-details {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	position: relative;
	gap: 12px;
	margin: 6px 8px;
	padding-left: 4px;
}

.card-title {
	justify-content: start;
	align-items: center;

	font-size: 17px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.card-location {
	display: inline-block;
	width: auto;
	align-items: center;
	font-size: 0.8rem;
	color: black;
}

.card-rating {
	font-size: 0.9rem;
	margin: 0;
	display: inline-block;
}

.card-price {
	font-size: 1.4rem;
	font-weight: bold;
	position: absolute;
	bottom: 0px;
	right: 0;
}

.icon-search {
	margin-right: 10px;
}

.card:hover {
	transition: all 110ms ease-in-out;
	box-shadow:
    -40px -40px 0 -35px rgb(var(--second-color)),
    40px 40px 0 -35px rgb(var(--main-color));
}

.stars-count {
	font-size: 12px;
}

.counter-reviews {
	font-size: 0.8rem;
}

.stars-row {
	display: flex;
}

h2.card-title {
	color: black;
}

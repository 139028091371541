div.faq-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	align-items: center;
	/* padding: 3rem 20rem 3rem 20rem; */
	background-color: white;
	height: 100vh;
}
.profile-thumbnail {
	width: 95px;
	height: 95px;
	object-fit: cover;
	border-radius: 50%;
}
h1#title-about {
	margin: 20px 0;
	text-align: center;
	background-color: white;
	height: 10rem;
	padding: 4rem;
	margin-bottom: 0;
}

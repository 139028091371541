.card-category {
	border-radius: 10px;
	box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.2);
	overflow: hidden;
	cursor: pointer;
	
	width: 80%;
	max-width: 360px;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	min-height: 240px;
	margin: 20px auto;
}
.card-figure-category {
	overflow: hidden;
	width: 100%;
	height: 140px;
}
.card-thumbnail-category {
	overflow: hidden;
	width: 100%;
	height: 140px;
	object-fit: cover;
}
.title-card-category {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	margin: auto 6px;
}

.row-button-category {
	width: 100%;
	padding: 10px;
	display: flex;
	justify-content: space-around;
}

.btn-category-option {
	width: 30%;
	height: 2rem;
	color: white;
	background-color: rgb(var(--primary-color-hard));
	border: none;
	border-radius: 9px;
	cursor: pointer;
}



#btn-active {
	background-color: grey;
}

.hero {
	position: relative;

	width: 100%;
	height: 450px;

	display: flex;
	flex-direction: column;
	position: relative;
	background: url("https://source.unsplash.com/random/?adventures");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
	justify-content: center;
	align-items: center;

	z-index: 1;
}

.hero::after {
	content: "";
	position: absolute;
	width: 100%;

	height: 450px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background: rgba(0, 0, 0, 0.461);
	z-index: -1;
}

.searchContainer {
	min-width: 320px;
	display: flex;
	justify-content: center;
	align-items: cen;
}

.filterContainer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	align-content: center;
	text-align: center;
	justify-content: center;
	padding: 10px 0;
	background-color: rgb(var(--primary-color-soft));
}

.searchBar {
	display: flex;
	justify-content: center;
	max-width: 80%;
}
.priceSearch,
.categorySearch,
.locationSearch,
.ratingSearch {
	display: flex;

	justify-content: center;
}

.selectFilter {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	width: 100vw;
	text-align: center;
}

.priceRateFilter {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	width: 100vw;
	text-align: center;
}

/* .searchBar:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
} */

.button-search {
	background-color: transparent;
	color: white;
	border: none;
	font-size: 1em;
	cursor: pointer;
	position: relative;
	right: 25px;
}

.search-form {
	width: 90%;
	max-width: 700px;
	z-index: 2;
	display: flex;
	justify-content: space-between;
}

.input-search {
	width: 100%;
	color: white;
	padding: 8px 18px;
	background-color: rgba(0, 0, 0, 0.6);
	font-size: 1.5rem;
	border: none;
	letter-spacing: 2px;
	border-radius: 9px;
}

.input-search:focus {
	outline: none;
}

.search-button {
	padding: 6px;
	border: none;
	color: white;
	background-color: transparent;
	/* position: absolute; */
	height: 2rem;
	font-size: 1.4rem;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	transform: translate(-50px, 1px);
}

.calendar-button {
	border: none;
	color: white;
	background-color: transparent;
	font-size: 2rem;
	cursor: pointer;
}

.search-button:hover {
	transform: scale(1.4);
	transform: translate(-50px, 1px);
}

.filter-toggle {
	display: flex;
	justify-content: center;
}

.generalFilter {
	/* font-size: 0.7rem; */
	/* padding: 4px 10px; */
}

.order{
	text-align: center;
}

.button-toggle {
	padding: 10px 15px;
	background-color: black;
	color: white;
	border: none;
	cursor: pointer;
}

.filterButton {
	background-color: rgb(var(--second-color));
	color: white;
	padding: 5px 15px;
	letter-spacing: 1px;
	cursor: pointer;
	border-radius: 9px;
	border: none;
	margin: 5px;
	transition: all 0.3s ease-in-out;
}

.filterButton:hover {
	transform: scale(1.1);
}

.generalFilter {
	display: flex;
	justify-content: center;
	border-radius: 9px;
	height: 30px;
	min-width: 140px;
	max-width: 170px;
	border: none;
	box-shadow: 2px 2px 4px gray;
	background-color: white;
	margin: 10px 0;
	padding: 0 12px;
}

.generalFilter option {
	text-align: left;
}

.generalFilter option:active {
 background-color: rgb(174, 0, 255);
}

.custom-input {
	display: none;
}

div.locationSearch > p {
	color: black;

	font-size: 16px;
}

div.categorySearch > p {
	color: black;

	font-size: 16px;
}

div.priceSearch > p {
	color: black;

	font-size: 16px;
}

div.ratingSearch > p {
	color: black;

	font-size: 16px;
}

/* button.filterButton {
	display: flex;
	padding: 7px 5px 7px 5px;
	font-size: 17px;
	color: white;
	text-transform: uppercase;
	border: 1px solid white;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.274);
} */

.order-by{
	font-size: 0.7rem;
   display: flex;
   align-items: center;
   gap: 0.7rem;
   background-color: white;
   padding: 1rem 5%;
}

@media screen and (max-width: 520px) {
	.priceSearch,
	.categorySearch,
	.locationSearch,
	.ratingSearch {
		display: flex;
		width: 50%;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.hero {
		height: 200px;
	}

	.hero::after {
		height: 200px;
	}

	#principal > h1 {
		font-size: 21px;
	}
}

@media screen and (min-width: 820px) {
	.priceSearch,
	.categorySearch,
	.locationSearch,
	.ratingSearch {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		width: 150px;
		justify-content: center;
		align-items: center;
	}
}

@media screen and (min-width: 1200px) {
	.priceSearch,
	.categorySearch,
	.locationSearch,
	.ratingSearch {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		width: 170px;
		justify-content: center;
		align-items: center;
		/* position: relative;
		top: 20px; */
	}
}

@media screen and (min-width: 1400px) {
	.hero {
		height: 450px;
	}
	.hero::after {
		height: 450px;
	}
}

.change::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: #fff;
	font-weight: 200;
}
.change:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #fff;
	opacity: 0.2;
	font-weight: 200;
}
.change::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #fff;
	opacity: 0.2;
	font-weight: 200;
}
.change:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #fff;
	font-weight: 200;
}

.nav-user {
	position: absolute;
	right: 0;
	display: flex;
	flex-direction: column;
	margin-top: 4.6rem;
	z-index: 3;
	background-color: rgb(var(--primary-color-soft));
	opacity: 95%;
}
.nav-user ul li {
	list-style: none;
	cursor: pointer;
	font-size: 17px;
	text-align: center;
	padding: 1rem;
	font-weight: bold;
	color: black;
	transition: all 0.3s ease-in-out;
}

.nav-user ul li:hover {
	transform: scale(1.1);
}

.nav-user a {
	color: black;
	text-decoration: none;
	list-style: none;
	cursor: pointer;
	font-size: 15px;
	text-align: center;
	padding: 1rem;
	font-weight: bold;
}

@media screen and (min-width: 1400px) {
	.nav-user {
		right: auto;
	}
}

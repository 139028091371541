.admin-exp-wrapper {
	background-color: white;
}

#title-admin-cat {
	text-align: center;
	background-color: white;
}

.input-search {
	display: flex;
	justify-content: space-between;
}

#link-create-cat {
	margin: 16px;
	display: flex;
	justify-content: center;
	text-decoration: none;
}

.error-info {
	margin: 5rem 0;
	text-align: center;
	width: 100%;
	font-size: 2rem;
}

.form-wrap {
	margin-bottom: 50px;
	display: flex;
	flex-wrap: wrap;
}

.create-exp a {
	text-decoration: none;
}

.change::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: #fff;
	font-weight: 200;
}
.change:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #fff;
	opacity: 0.2;
	font-weight: 200;
}
.change::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #fff;
	opacity: 0.2;
	font-weight: 200;
}
.change:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #fff;
	font-weight: 200;
}

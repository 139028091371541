.inputForm {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	text-align: left;
}
/* 
.btn-back{

  background-color: white;
	margin-top: 500px;
	color: grey;
	font-size: 1.2rem;
	width: 7rem;
	border-radius: 20px;
	height: 2rem;

	border: 2px solid grey;
	letter-spacing: 1px;
	cursor: pointer; 
	margin-top: 1rem;
	} */

.edit-sect-activar {
	margin-top: 20px;
}

h1#create-title {
	text-align: center;
	font-size: 2em;
	height: 10rem;
	padding: 4rem;
	margin-bottom: 0;
	color: white;
}

.rmdp-input {
	min-width: 244px;
	margin-top: 1px;
	text-align: center;
	display: flex;
	width: 75%;
	max-width: 400px;
	justify-content: left;
	height: 2rem;
	border: none;
	margin: 0.5rem 0;
	padding-left: 6px;
	border-bottom: 0.5px solid rgb(225, 225, 225);
	background-color: transparent;
	color: black;
	font-weight: 300;
}

.generalInput::placeholder{
	color: grey;
	opacity: 1;
}
